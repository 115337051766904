/*--------------------------------------------------------------
## Color Variable
----------------------------------------------------------------*/
$white: #ffffff;
$black: #000;
$primaryorg: #274760;
$primary: #005fc3;
//$secondary: rgba($primary, 0.52);
$secondary: #0a9cda;
$ternary: #b7b7b7;
$border: #eaeaea;
$gray: #fafafa;
$accent: #307bc4;
$text: #878180;