.cs_site_header.cs_style1.cs_active_sticky {
  background-color: #fff;
}
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky {
  background-color: #274760;
}
.cs_accent_bg .cs_social_links_wrap h2,
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky .cs_toolbox {
  color: #fff;
}
.cs_contact_widget i {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  color: #fff;
  padding: 4px;
}
.cs_video_open {
  cursor: pointer;
}
.cs_hero.cs_style_1 {
  .cs_text_btn_2,
  .cs_hero_title,
  .cs_hero_subtitle {
    position: relative;
    z-index: 1;
  }

  .cs_hero_text {
    position: initial;
    z-index: initial;
  }
}
.cs_accordian_body_wrap {
  overflow: hidden;
  transition: all 0.4s ease;
}
.cs_tabs.cs_style1 .cs_tab_links .cs_tab_link_in {
  cursor: pointer;
}
.cs_rating {
  i {
    display: flex;
  }
}
.cs_shape_wrap .cs_shape_2 {
  top: -3%;
}
.cs_cs_slider_navigation_1 {
  .cs_slider_next,
  .cs_slider_prev {
    transition: all 0.4s ease;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    height: 60px;
    transform: translateY(-50%);
    background-color: #fff;
    width: 70px;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    position: absolute;
    top: 50%;
    display: flex !important;
    z-index: 1;
    @media (min-width: 991px) {
      opacity: 0;
    }
  }
  .cs_slider_next {
    right: -35px;
  }
  .cs_slider_prev {
    left: -35px;
  }
  &:hover {
    .cs_slider_next,
    .cs_slider_prev {
      opacity: 1;
    }
  }
}
.cs_cs_slider_navigation_2 {
  .cs_slider_next,
  .cs_slider_prev {
    height: 40px;
    width: 40px;
    border: 2px solid $accent;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.4s ease;
    cursor: pointer;
    position: absolute;
    display: flex !important;
    bottom: 0;
  }
  .cs_slider_next {
    right: 0;
  }
  .cs_slider_prev {
    right: 52px;
  }
}
.cs_cs_slider_navigation_3 {
  .cs_slider_next,
  .cs_slider_prev {
    width: 90px;
    height: 70px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex !important;
    position: absolute;
    top: -213px;
    @media (max-width: 1700px) {
      width: 80px;
      height: 60px;
      top: -188px;
    }
    @media (max-width: 991px) {
      display: none !important;
    }
    img {
      transition: inherit;
    }
    &:hover {
      background-color: $accent;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .cs_slider_next {
    right: 0;
  }
  .cs_slider_prev {
    right: 115px;
    @media (max-width: 1700px) {
      right: 100px;
    }
  }
  .slick-dots {
    display: none !important;
    @media (max-width: 991px) {
      display: flex !important;
    }
  }
}
.cs_related_doctor {
  padding-bottom: 90px;
  @media (max-width: 400px) {
    padding-bottom: 75px;
  }
  .slick-dots {
    bottom: 15px;
  }
}
.cs_gallery_grid_1 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    .cs_grid_item {
      &:nth-child(2) {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .cs_section_heading.cs_style_1 {
    min-height: 263px;
    @media (max-width: 1700px) {
      min-height: 166px;
    }
    @media (max-width: 991px) {
      min-height: initial;
    }
  }
  .cs_grid_item {
    &:first-child {
      grid-column: span 2;
    }
    @media (min-width: 992px) {
      &:nth-child(6) {
        grid-column: span 2;
      }
    }

    &:nth-child(5) {
      .cs_portfolio.cs_style_1 {
        height: 767px;
        margin-top: -338px;
        @media (max-width: 1700px) {
          height: 590px;
          margin-top: -240px;
        }
        @media (max-width: 991px) {
          margin-top: 0;
          height: 400px;
        }
      }
      @media (max-width: 991px) {
        grid-column: span 2;
      }
      @media (max-width: 767px) {
        grid-column: initial;
      }
    }
  }
  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
}
.cs_gallery_grid_2 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px 0px;
  }

  .cs_grid_item {
    @media (min-width: 768px) {
      &:first-child {
        grid-column: span 2;
      }
    }

    @media (min-width: 992px) {
      &:nth-child(7) {
        grid-column: span 2;
      }
    }
  }
  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
  @media (max-width: 420px) {
    .cs_portfolio.cs_style_1 {
      height: 300px;
    }
  }
}
.cs_portfolio.cs_style_1 .cs_portfolio_img > div > img {
  cursor: zoom-in;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.cs_team_grid {
  display: grid;
  grid-gap: 50px 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 25px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px 25px;
  }
  &.cs_list_view_wrap {
    grid-template-columns: repeat(1, 1fr);
  }
  &.cs_grid_view_wrap {
    .cs_team.cs_style_1.cs_type_2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.cs_isotop_filter.cs_style1 {
  text-transform: capitalize;
}
.cs_pagination_box {
  .cs_pagination_item,
  .cs_pagination_arrow {
    border: none;
    &:hover {
      color: $accent;
    }
  }
  .cs_pagination_arrow {
    background-color: transparent;
  }
}
.form-check .form-check-input {
  border-color: #636363;
}
.cs_related_doctor .slick-dots {
  display: flex !important;
}
.react-datepicker-wrapper {
  display: block;
}
.cs_blog_grid_1 {
  display: grid;
  grid-gap: 20px 130px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1700px) {
    grid-gap: 20px 80px;
  }
  @media (max-width: 1400px) {
    grid-gap: 20px;
  }
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 992px) {
    > * {
      grid-column: span 2;
      &:first-child {
        grid-row: span 3;
      }
    }
  }
}
.cs_error {
  padding: 80px 0;
  min-height: 100vh;
}
.cs_nav .cs_nav_list .cs_munu_dropdown_toggle.active + ul {
  display: block;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.cs_rotate{
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;

  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;

  overflow:hidden;

}

.cs_rotate:hover
{
  -webkit-transform:rotate(360deg);
  -moz-transform:rotate(360deg);
  -o-transform:rotate(360deg);
}
.cs_big{
  img{
    transition: all 0.4s ease-in-out;
  }
  img:hover {
      transform: scale(1.1);
    }
}
.cs_shake{
  img:hover {
    animation: Shake 0.5s linear infinite;
  }

  /*Using keyframes for shaking an image*/
  @keyframes Shake {
    0% {
      transform: rotate(5deg);
    }

    25% {
      transform: rotate(-6deg);
    }

    50% {
      transform: rotate(5deg);
    }

    75% {
      transform: rotate(-6deg);
    }

    100% {
      transform: rotate(5deg);
    }
  }
}
.cs_fs_color_white{
  color: $white;
}
.cs_text_web_color{
  color: $text;
}
.cs_primary_color_bg {
  background-color: $primary;
}
.cs_primary_org_color {
  color: $primaryorg;
}
.cs_footer_dr_name{
  font-size: 20px;
  font-weight: bold;
}

.cs_fs_small{
  font-size: small;
  line-height: 0.9em;
}

.cs_fs_90 {
  font-size: 90px;
  line-height: 0.9em;
  @media (max-width: 1700px) {
    font-size: 68px;
  }
  @media (max-width: 1199px) {
    font-size: 56px;
  }
  @media (max-width: 400px) {
    font-size: 38px;
  }
}

.cs_footer_mt150{
  margin-top: 150px;
}

.cs_footer_custom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px 30px;
  flex-wrap: wrap;
  @media (max-width: 575px) {
    flex-direction: column;
    text-align: center;
  }
}

.cs_footer_bottom_custom {
  text-align: center;
  border-top: 1px solid rgba($white, 0.2);
  padding: 20px 0;
}

.cs_social_net_links_wrap {
  display: flex;
  align-items: center;
  gap: 20px 30px;
  h2 {
    font-size: 18px;
    font-weight: lighter !important;
    margin: 0;
    color:#ffffff;
  }
}
.cs_credit_card_links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4em;
  margin-bottom: 13px;
  img {
    height: 3em;
    width:3em;

    display: flex;
    align-items: center;
    border-radius: 50%;
    background-color: rgba($white, 0.5);
    color: $accent;
    font-size: 12px;
    justify-content: center;
    &:hover {
      transform: scale(1.1);
      transition-duration: 0.5s;
      background-color: #fff;
    }
  }
}
@-webkit-keyframes shake {
  0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
}
.shake:hover {
  -webkit-animation-name: shake;
  -webkit-animation-duration: 0.5s;
  -webkit-transform-origin:50% 50%;
  -webkit-animation-iteration-count: infinite;
}
.shake {
  display:inline-block
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes jumpTwo {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes jumpTwo {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 40px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes jumpThree {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes jumpThree {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes jumpFour {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  50% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes jumpFour {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  50% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes jumpFive {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  50% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes jumpFive {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  50% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes prXOne {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes prXOne {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes prXTwo {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes prXTwo {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes rotatedTwo {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg)
  }
}

@keyframes rotatedTwo {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg)
  }
}

@-webkit-keyframes rotatedHalf {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@keyframes rotatedHalf {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@-webkit-keyframes rotatedHalfTwo {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
}

@keyframes rotatedHalfTwo {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
}

@-webkit-keyframes scale-upOne {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  50% {
    -webkit-transform: scale(.85);
    transform: scale(.85)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes scale-upOne {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  50% {
    -webkit-transform: scale(.85);
    transform: scale(.85)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes scale-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(20%);
    transform: translateX(20%)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}


@keyframes tpfadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes scale-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(20%);
    transform: translateX(20%)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: .2
  }

  40% {
    opacity: 1
  }

  100% {
    opacity: .2
  }
}

@keyframes fade-in {
  0% {
    opacity: .2
  }

  40% {
    opacity: 1
  }

  100% {
    opacity: .2
  }
}

@keyframes hvr-ripple-out {
  0% {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px
  }

  50% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: .8
  }

  100% {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    opacity: 1
  }
}

@keyframes hvr-ripple-out-two {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  100% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
    opacity: 0
  }
}

@-webkit-keyframes scale-up-one {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  40% {
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes scale-up-one {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  40% {
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes scale-up-two {
  0% {
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }

  40% {
    -webkit-transform: scale(.8);
    transform: scale(.8)
  }

  100% {
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }
}

@keyframes scale-up-two {
  0% {
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }

  40% {
    -webkit-transform: scale(.8);
    transform: scale(.8)
  }

  100% {
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }
}

@-webkit-keyframes scale-up-three {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  40% {
    -webkit-transform: scale(2);
    transform: scale(2)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes scale-up-three {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  40% {
    -webkit-transform: scale(2);
    transform: scale(2)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes jump5p {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes jump5p {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes jump5pRsv {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes jump5pRsv {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes moveclouds {
  0% {
    margin-left: 200px
  }

  100% {
    margin-left: -200px
  }
}

@-webkit-keyframes moveclouds {
  0% {
    margin-left: 200px
  }

  100% {
    margin-left: -200px
  }
}

@keyframes moveclouds2 {
  0% {
    margin-right: 200px
  }

  100% {
    margin-right: -200px
  }
}

@-webkit-keyframes moveclouds2 {
  0% {
    margin-right: 200px
  }

  100% {
    margin-right: -200px
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(-70%)
  }
}

@-webkit-keyframes moveUp {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(-70%)
  }
}
@keyframes tpfadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.tpfadeUp {
  -webkit-animation-name: tpfadeUp;
  animation-name: tpfadeUp;
}

@-webkit-keyframes tpfadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes tpfadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    transform: translateX(-60px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.tpfadeLeft {
  -webkit-animation-name: tpfadeLeft;
  animation-name: tpfadeLeft;
}

@-webkit-keyframes tpfadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes tpfadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.tpfadeRight {
  -webkit-animation-name: tpfadeRight;
  animation-name: tpfadeRight;
}


@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}




@-webkit-keyframes tpfadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tpfadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tpfadeIn {
  -webkit-animation-name: tpfadeIn;
  animation-name: tpfadeIn;
}


@keyframes movingleftright1 {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }

  40% {
    -webkit-transform: translateX(50px) translateY(-100px);
    transform: translateX(50px) translateY(-100px);
  }

  75% {
    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
    box-shadow: 0 0 0 45px rgba(255,255,255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}

@keyframes animationglob {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes tpupdown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes tptranslateY2{
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes tptranslateX2{
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(40px);
    -moz-transform: translateX(40px);
    -ms-transform: translateX(40px);
    -o-transform: translateX(40px);
    transform: translateX(40px);
  }
}

@keyframes section-animation {
  0% {
    width: 0;
  }
  15% {
    width: 100%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 100%;
    opacity: 0;
  }
  to {
    width: 0;
    opacity: 0;
  }
}

